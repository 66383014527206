import _ from "lodash";
import { PersonalDetails } from "../personal-details/personal-details";

export enum OrderConsentType {
  Submit = "SUBMIT",
  Deliver = "DELIVER",
}

export interface OrderConsent {
  id: number | null;
  client_code: string;
  practitioner: number | null;
  personal_details?: PersonalDetails;
  type: OrderConsentType;
  consents: boolean;
  consents_to: string;
  consented_at: Date;
}

export const consentOfType = (
  type: OrderConsentType,
  consents?: OrderConsent[]
): OrderConsent | null => {
  return (
    _.findLast(
      consents || [],
      (consent: OrderConsent) => consent.type === type
    ) || null
  );
};
