export const PAGE_SIZE_OPTIONS: number[] = [5, 10, 25, 50, 100];
export const DEFAULT_PAGE_SIZE: number = 5;

export const limitOffsetToPage = (limit: number, offset: number) => {
  //pages in EUI are zero based
  const page = limit > 0 ? Math.max(0, offset / limit) : 0;
  return page;
};

export const pagination = (
  total: number,
  limit: number = DEFAULT_PAGE_SIZE,
  offset: number = DEFAULT_PAGE_SIZE,
  pageSizeOptions: number[] = PAGE_SIZE_OPTIONS
) => {
  return {
    pageIndex: limitOffsetToPage(limit, offset),
    pageSize: limit,
    totalItemCount: total,
    pageSizeOptions: pageSizeOptions,
    showPerPageOptions: false,
  };
};
