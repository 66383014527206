import { Font } from "@react-pdf/renderer";
import fontFuturaPTBold from "../assets/fonts/futura-pt-bold.ttf";
import fontFuturaPT from "../assets/fonts/futura-pt.ttf";
import { A4_H_5, A4_P_S, A4_W_10, style } from "./params";

export const styles = style({
  font: {
    fontFamily: "futura-pt",
    fontSize: "10pt",
  },
  title: {
    fontWeight: 600,
    fontSize: "14pt",
  },
  subtitle: {
    fontWeight: 600,
    fontSize: "10pt",
  },
  small: {
    fontSize: "9pt",
  },
  soft: {
    color: "#777777",
  },
  logo: {
    width: "120pt",
    height: "18pt",
    objectFit: "contain",
  },
  logoFooter: {
    width: "80pt",
    height: "12pt",
    objectFit: "contain",
  },
  addressPaneHolder: {
    height: A4_H_5,
    width: A4_W_10,
    padding: A4_P_S,
    justifyContent: "center",
    // borderWidth: 1,
  },
  addressPane: {
    // flexGrow: 1,
  },
  p: {
    padding: `${A4_P_S} 0pt`,
  },
});

export const initPdfStyles = () => {
  Font.register({
    family: "futura-pt",

    fonts: [{ src: fontFuturaPT }, { src: fontFuturaPTBold, fontWeight: 600 }],
  });

  Font.registerEmojiSource({
    format: "png",
    url: "https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/",
  });
};
