import { EuiFlexGroup, EuiFlexItem } from "@elastic/eui";
import { useEffect, useState } from "react";
import { Contract } from "store/data/contract/contract";
import { Costing, COSTING_EMPTY } from "store/data/costing/costing";
import { MMOrderLineCostingFieldProps } from "../costing";
import MMCostingContractsList from "./costing-contracts-list";

function MMCostingContract(props: MMOrderLineCostingFieldProps) {
  const [costing, setCosting] = useState<Costing | null | undefined>();
  const [contracts, setContracts] = useState<Contract[]>([]);
  const [isYearVisible, setIsYearVisible] = useState<boolean>(false);

  useEffect(() => {
    setCosting(props.costing);
  }, [props.costing, props.isYearVisible]);

  useEffect(() => {
    setIsYearVisible(!!props.isYearVisible);
    console.log("setIsYearVisible", props.isYearVisible);
  }, [props.isYearVisible]);

  useEffect(() => {
    setContracts(props.contracts || []);
  }, [props.contracts]);

  const onContractSelected = (contract: Contract) => {
    if (props.onChange) {
      let updatedCosting: Costing = { ...(costing || COSTING_EMPTY) };

      updatedCosting.contract_id = contract.id;
      updatedCosting.contract = { ...contract };
      if (!updatedCosting.amount && contract.product_declaration_value) {
        updatedCosting.amount = contract.product_declaration_value;
      }
      if (contract.measuring_fee_first && !updatedCosting.amount_effort) {
        updatedCosting.amount_effort = contract.measuring_fee_first;
        updatedCosting.description_effort =
          contract.measuring_fee_first_code || "";
      }
      if (
        (contract.authorization === true || contract.authorization === false) &&
        updatedCosting.authorization !== contract.authorization
      ) {
        updatedCosting.authorization = contract.authorization;
      }

      props.onChange(updatedCosting, props.orderLineIndex);
    }
  };

  return (
    <EuiFlexGroup gutterSize="xs">
      <EuiFlexItem>
        <MMCostingContractsList
          search={props.baseArticleCode}
          costing={costing}
          contracts={contracts}
          selectable={true}
          isEditable={props.isEditable}
          isYearVisible={isYearVisible}
          onSelect={onContractSelected}
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
}

export default MMCostingContract;
