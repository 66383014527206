import txt from "helpers/text-helper";
import { Contract } from "../contract/contract";
import { File } from "../file/file";
import { Insurer } from "../insurer/insurer";
import { OrderLine } from "../order/order";
import { PersonalDetails } from "../personal-details/personal-details";

export const VAT_LOW: number = 0.09;
export const VAT_HIGH: number = 0.21;
export const VAT_ZERO: number = 0;

export enum CostingCheck {
  Quoted = "QUOTED",
  Invoiced = "INVOICED",
}

export enum CostingPaymentType {
  Declaration = "DECLARATION",
  B2B = "B2B",
  B2C = "B2C",
  None = "NONE",
  Unknown = "UNKNOWN",
}

export enum OrderCostingType {
  Declaration = "DECLARATION",
  InvoicePrivate = "INVOICE_PRIVATE",
  InvoiceBusiness = "INVOICE_BUSINESS",
}

export const COSTING_CHECK_LOG_EMPTY: CostingCheckLog = {
  id: null,
  order_line_costing_id: null,
  check: null,
  value: null,
  comment: null,
  by: null,
  date: new Date(),
  personal_details: null,
};

export const COSTING_EMPTY: Costing = {
  id: null,
  order_line_id: null,
  contract_id: null,
  contract: null,
  authorization: null,
  paymentType: CostingPaymentType.Unknown,
  amount: null,
  amount_effort: null,
  description: "",
  description_effort: "",
  is_quoted: false,
  is_authorized: false,
  is_invoiced: false,
  is_declined: false,
  declined_reason: "",
  authorization_code: "",
  authorization_code_received_at: null,
  quotation_code: "",
  checks: [],
};

export const ORDER_COSTING_EMPTY: OrderCosting = {
  id: null,
  rationale: null,
  type: OrderCostingType.Declaration,
  notes: "",
  rationale_notes: "",
  quotation: null,

  files: [],
};

export interface CostingGroup {
  groupKey: string;
  orderCosting?: OrderCosting;
  insurer?: Insurer;
  lines: Costing[];
}
export interface CostingCheckLog {
  id: number | null;
  order_line_costing_id: number | null;
  check: string | null;
  value: boolean | null;
  comment: string | null;
  by: string | null;
  date: Date;
  personal_details: PersonalDetails | null;
  order_line_costing?: {
    order_line?: {
      id: number;
      code: string;
    };
  };
}

export interface Costing {
  id: number | null;
  order_line_id: number | null;
  contract_id: number | null;
  contract: Contract | null;
  authorization: boolean | null;
  paymentType: CostingPaymentType;
  amount: number | null;
  amount_effort: number | null;
  description: string | null;
  description_effort: string | null;
  is_quoted: boolean;
  is_authorized: boolean;
  is_invoiced: boolean;
  is_declined: boolean;
  authorization_code: string;
  authorization_code_received_at: Date | null;
  declined_reason: string;
  quotation_code: string;
  checks: CostingCheckLog[];
  order_line?: OrderLine;
  last_quoted_at?: Date | null;
}

export interface OrderCosting {
  id: number | null;
  rationale: string | null;
  type: OrderCostingType;
  notes: string | null;
  rationale_notes: string | null;
  quotation: Quotation | null;
  files: File[];
}

export interface Quotation {
  id: number | null;
  code: number | null;
}

export const orderLineCostingTotal = (
  costings: Costing[],
  vat?: number,
  vatOnly: boolean = false
) => {
  let amountTotal: number = 0;
  let vatTotal: number = 0;
  for (let i = 0; i < costings.length; i++) {
    const costing: Costing = costings[i];

    amountTotal +=
      vat === undefined
        ? costing.amount || 0
        : (costing.amount || 0) / (1 + (vat || 0));
    vatTotal +=
      (costing.amount || 0) - (costing.amount || 0) / (1 + (vat || 0));

    amountTotal +=
      vat === undefined
        ? costing.amount_effort || 0
        : (costing.amount_effort || 0) / (1 + (vat || 0));

    vatTotal +=
      (costing.amount_effort || 0) -
      (costing.amount_effort || 0) / (1 + (vat || 0));
  }
  return vatOnly ? vatTotal : amountTotal;
};

export const costingTotal = (
  orderLines: OrderLine[],
  vat?: number,
  vatOnly: boolean = false
) => {
  let costings: Costing[] = [];
  for (let i = 0; i < orderLines.length; i++) {
    const orderLine: OrderLine = orderLines[i];
    if (orderLine.costing) {
      costings.push(orderLine.costing);
    }
  }
  return orderLineCostingTotal(costings, vat, vatOnly);
};

// add to contract data?
// 21 - aanschaf hulpmiddel in eigendom
// 23 - reparatie hulpmiddel in eigendom
// 24 - aanpassingen hulpmiddel in eigendom

export const costingWaitingForDescription = (costing: Costing) => {
  let result: string = "";
  //if (costing.is_paid) {

  //}
  if (costing.is_invoiced) {
    result = txt.get("orders.order.costing.waiting_for.invoiced");
  } else if (
    (costing.authorization && costing.is_authorized) ||
    !costing.authorization
  ) {
    result = txt.get("orders.order.costing.waiting_for.invoice");
  } else if (
    costing.authorization &&
    !costing.is_authorized &&
    costing.is_quoted
  ) {
    result = txt.get("orders.order.costing.waiting_for.authorization");
  } else if (
    costing.authorization &&
    !costing.is_authorized &&
    !costing.is_quoted
  ) {
    result = txt.get("orders.order.costing.waiting_for.quote");
  }
  return result;
};

export const costingTypeDescription = (type: OrderCostingType) => {
  return txt.get(
    `orders.order.costing.costing_type_option.${type.toLowerCase()}`
  );
};
export const toCostType = (orderLine: OrderLine) => {
  if (
    ["C0001", "C0002", "C0003", "C0004", "C0005", "C0006", "C0007"].includes(
      orderLine.code.substring(0, orderLine.code.indexOf("-"))
    )
  ) {
    return "23";
  }
  return "21";
};

export const toGroupedCostings = (costings: Costing[]) => {
  let result: any = {};

  for (let i = 0; i < costings.length; i++) {
    const costing: Costing = costings[i];

    const groupKey: string = `${costing.order_line?.order?.costing?.id}-${costing.order_line?.hand}-${costing.order_line?.product_selection.type}-${costing.order_line?.order_type}-${costing.order_line?.status}`;
    if (!result[groupKey]) {
      result[groupKey] = {
        groupKey,
        orderCostingId: costing.order_line?.order?.costing?.id,
        orderCosting: costing.order_line?.order?.costing,
        insurer: costing.contract?.insurer,
        lines: [],
      } as CostingGroup;
    }
    result[groupKey].lines.push(costing);
  }
  const groups: CostingGroup[] = Object.values(result) as CostingGroup[];
  return groups;
};

export const toCostingLogDescription = (costingLog: CostingCheckLog) => {
  if (!costingLog.check) return "-";

  let checkName: string = costingLog.check;
  checkName = checkName.replace("is_", "");
  if (checkName === "authorization" && costingLog.value === undefined) {
    checkName = "unsure_authorization";
  } else {
    checkName = `${costingLog.value ? "" : "not_"}${checkName}`;
  }
  return txt.lo(`orders.order.costing.${checkName}`);
};
