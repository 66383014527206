import { createSlice, nanoid, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store/store";

export interface ToastInfo {
  id: string;
  title: string;
  text?: string;
  code?: string;
  importance?: string;
}
export interface ToastState {
  toasts: ToastInfo[];
}

const initialState: ToastState = {
  toasts: [],
};

export const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    toastAdd: {
      reducer(state, action: PayloadAction<ToastInfo>) {
        state.toasts.push(action.payload);
      },
      prepare(title, text?, importance?, code?) {
        return {
          payload: {
            id: nanoid(),
            title,
            text,
            importance,
            code,
          },
        };
      },
    },
    toastRemove: {
      reducer(state, action: PayloadAction<string>) {
        state.toasts = state.toasts.filter(
          (toast) => toast.id !== action.payload
        );
      },
      prepare(id) {
        return { payload: id };
      },
    },
  },
});

export const toastsGet = (state: RootState) => state.toast.toasts;

export const { toastAdd, toastRemove } = toastSlice.actions;

export default toastSlice.reducer;
