import {
  EuiComboBox,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  useIsWithinBreakpoints,
} from "@elastic/eui";
import txt from "helpers/text-helper";
import { updateField } from "hoc/helper-hooks";
import { useEffect, useState } from "react";
import {
  MMOrderLineFieldProps,
  MMOrderLineProductInput,
} from "../order-line-entry";

const toOrderTypeOption = (selection: any, orderTypeOptions: any) => {
  let result: any;
  console.log("toOrderTypeOption", selection, orderTypeOptions);

  if (selection && selection.orderType && orderTypeOptions) {
    result = orderTypeOptions.find(
      (orderTypeOption: any) => orderTypeOption.value === selection.orderType
    );
  }

  return result;
};

function MMOrderType(props: MMOrderLineFieldProps) {
  const isMobile: boolean = useIsWithinBreakpoints(["xs", "s"]);

  const [orderTypeOptions, setOrderTypeOptions] = useState<any[]>([]);

  const [selectedOrderType, setSelectedOrderType] = useState<any>();

  const onOrderTypeChanged = (orderType: any) => {
    if (props.onChange) {
      props.onChange({
        ...(props.selection || {}),
        orderType: orderType && orderType.value ? orderType.value : "",
      } as MMOrderLineProductInput);
    } else {
      setSelectedOrderType(orderType);
    }
  };

  useEffect(() => {
    if (!props.orderTypes) {
      setOrderTypeOptions([]);
    } else {
      setOrderTypeOptions(
        props.orderTypes.map((orderType: any) => ({
          label: orderType.name,
          value: orderType.name,
        }))
      );
    }
  }, [props.orderTypes]);

  useEffect(() => {
    if (
      (!props.selection || !props.selection.orderType) &&
      props.orderTypes &&
      props.orderTypes.length === 1 &&
      props.onChange
    ) {
      props.onChange(
        updateField(props.selection, "orderType", props.orderTypes[0].name)
      );
    } else {
      setSelectedOrderType(
        toOrderTypeOption(props.selection, orderTypeOptions)
      );
    }
  }, [props.selection, orderTypeOptions]);

  return (
    <EuiFlexGroup gutterSize="s">
      <EuiFlexItem>
        <EuiFormRow
          className="product-input"
          display="rowCompressed"
          label={props.showTitle ? txt.get("orders.order.order_type") : ""}
        >
          <EuiComboBox
            className={selectedOrderType ? `option-selected` : ""}
            style={isMobile ? {} : {}}
            color="accent"
            singleSelection={{ asPlainText: true }}
            compressed={true}
            placeholder={txt.get("orders.order.order_type")}
            options={orderTypeOptions}
            selectedOptions={selectedOrderType ? [selectedOrderType] : []}
            data-testid="products-order-type"
            onChange={(selected) =>
              onOrderTypeChanged(selected.length > 0 ? selected[0] : null)
            }
          />
        </EuiFormRow>
      </EuiFlexItem>{" "}
    </EuiFlexGroup>
  );
}

export default MMOrderType;
