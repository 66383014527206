import { ApiResponse } from "api/api-helper";
import txt from "./text-helper";

export const formatApiError = (response: ApiResponse) => {
  const result = {
    title: "",
    message: "",
    importance: "danger",
    code: response.code,
  };

  if (response.message) {
    const codeKey = `errors.${response.code}`;
    const codeNameKey = `errors.${response.code}.name`;
    const codeDescriptionKey = `errors.${response.code}.description`;

    const nameKey = `errors.${response.message}.name`;
    const descriptionKey = `errors.${response.message}.description`;

    if (txt.has(codeNameKey) && txt.has(codeDescriptionKey)) {
      // Translated error code  title and message
      result.title = txt.get(codeNameKey);
      result.message = txt.get(codeDescriptionKey);
    } else if (txt.has(codeDescriptionKey)) {
      // Only translated code message
      result.title = txt.get("generic.error");
      result.message = txt.get(codeDescriptionKey);
    } else if (txt.has(codeKey)) {
      // Only translated code message
      result.title = txt.get("generic.error");
      result.message = txt.get(codeKey);
    } else if (txt.has(nameKey) && txt.has(descriptionKey)) {
      // Translated error title and message
      result.title = txt.get(nameKey);
      result.message = txt.get(descriptionKey);
    } else if (txt.has(descriptionKey)) {
      // Only translated message
      result.title = txt.get("generic.error");
      result.message = txt.get(descriptionKey);
    } else {
      // No translations for error, show generic title
      result.title = txt.get("generic.error");
      result.message = response.message;
    }
  } else {
    // No error message returned, show generic
    result.title = txt.get("generic.error");
    result.message = "";
  }

  return result;
};
